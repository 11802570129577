import { MetaDataNextSEO } from 'components/MetaDataNextSEO/MetaDataNextSEO';
import ClientNotFoundPage from 'components/PageLevelComponents/ClientNotFoundPage';
import { LOCALE_DEFAULT } from 'config/config';
import Script from 'next/script';
import { FC } from 'react';
import { NotFoundPageController } from 'server/controller/page/notFound/notFoundPageController';
import { INotFoundPageResource } from 'server/resource/page/notFoundPageResource';

const NotFoundPage: FC<INotFoundPageResource> = ({
  locale,
  errorData,
  metaData,
  usercentricsIds,
}) => {
  const localUsercentricIds = usercentricsIds && JSON.parse(usercentricsIds);
  const usercentricsId = localUsercentricIds && locale ? localUsercentricIds[locale] : null;

  return (
    <>
      {/* Cookie Banner - Usercentrics */}
      {usercentricsId && (
        <Script
          async
          id="usercentrics-cmp"
          data-settings-id={usercentricsId}
          src="https://web.cmp.usercentrics.eu/ui/loader.js"
          strategy="afterInteractive"
        />
      )}
      {metaData && <MetaDataNextSEO metaData={metaData} locale={locale} />}
      <ClientNotFoundPage locale={locale} errorData={errorData} />
    </>
  );
};

// This also gets called at build time
export const getStaticProps = async ({ locale }: { locale: string }) => {
  return NotFoundPageController.getNotFoundPageData({ locale: locale ?? LOCALE_DEFAULT });
};

export default NotFoundPage;
